<template>
  <div class="product">
    <NavBar />
    <v-breadcrumbs :items="breadCrumbsItems">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :exact="item.exact" :to="item.to" :disabled="item.disabled">
          <span :style="`color: ${item.color}`"> {{ item.text }}</span>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>

    <v-tabs v-model="selectedTabs" class="mt-7 mb-7 pa-3" slider-size="3" background-color="transparent" color="red">
      <v-tab>Общие данные</v-tab>
      <v-tab>Категории</v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedTabs" class="ma-3">
      <v-tab-item style="background-color: rgba(57, 74, 88, 0.2)">
        <div v-if="editedItem !== undefined && editedItem !== null" class="px-4 my-2 d-flex justify-center">
          <v-img v-if="!!picture" :src="url() + this.picture.path" alt="Изображение" max-height="300" max-width="400" />
          <v-img
            v-else-if="editedItem.image !== undefined && editedItem.image !== null"
            :src="url() + editedItem.image.path"
            alt="Изображение"
            max-height="300"
            max-width="400"
          />
          <div v-else>Изображение не установлено</div>
        </div>
        <v-row>
          <v-col>
            <v-btn type="submit" color="red" class="white--text my-1 mx-3" depressed v-on:click="clickButton">
              <v-icon left>mdi-content-save</v-icon> Сохранить
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col>
            <UploadPictureDialog :pictureType="'Product'" />
          </v-col>
        </v-row>
        <div v-if="editedItem !== undefined && editedItem !== null">
          <v-card-text>
            <v-form ref="form" @submit.prevent="checkInput">
              <v-row>
                <v-col cols="12" xs="6" sm="6" md="6" lg="6">
                  Название
                  <v-text-field
                    v-model="editedItem.nameRu"
                    :rules="notEmptyRules.concat(validationRules.nameForm)"
                    background-color="white"
                    class="mt-2"
                    dense
                    outlined
                  >
                  </v-text-field>
                  Описание
                  <v-textarea
                    v-model="editedItem.descriptionRu"
                    :rules="notEmptyRules.concat(validationRules.descriptionForm)"
                    background-color="white"
                    class="mt-2"
                    dense
                    outlined
                  >
                  </v-textarea>
                  Налоговая группа
                  <v-autocomplete v-model="editedItem.tax.token" :rules="notEmptyRules" :items="taxList" background-color="white" class="mt-2" dense outlined>
                  </v-autocomplete>
                  Цена
                  <v-text-field
                    v-model="editedItem.price"
                    :rules="notEmptyRules.concat(validationRules.priceForm)"
                    background-color="white"
                    class="mt-2"
                    dense
                    outlined
                  >
                  </v-text-field>
                  Остаток
                  <v-text-field
                    v-model.number="editedItem.amountLeft"
                    :rules="validationRules.amountRules"
                    background-color="white"
                    class="mt-2"
                    dense
                    outlined
                    hide-spin-buttons
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" xs="6" sm="6" md="6" lg="6">
                  Калории
                  <v-text-field
                    v-model="editedItem.calories"
                    background-color="white"
                    :rules="notEmptyRules.concat(validationRules.caloriesForm)"
                    class="mt-2"
                    dense
                    outlined
                  >
                  </v-text-field>
                  Углеводы
                  <v-text-field
                    v-model="editedItem.carbohydrates"
                    :rules="notEmptyRules.concat(validationRules.energyValueForm)"
                    background-color="white"
                    class="mt-2"
                    dense
                    outlined
                  >
                  </v-text-field>
                  Жиры
                  <v-text-field
                    v-model="editedItem.fats"
                    background-color="white"
                    :rules="notEmptyRules.concat(validationRules.energyValueForm)"
                    class="mt-2"
                    dense
                    outlined
                  >
                  </v-text-field>
                  Белки
                  <v-text-field
                    v-model="editedItem.proteins"
                    background-color="white"
                    :rules="notEmptyRules.concat(validationRules.energyValueForm)"
                    class="mt-2"
                    dense
                    outlined
                  >
                  </v-text-field>

                  Вес в граммах
                  <v-text-field
                    v-model="editedItem.weight"
                    :rules="notEmptyRules.concat(validationRules.weightRules)"
                    background-color="white"
                    class="mt-2"
                    dense
                    outlined
                    hide-spin-buttons
                    type="number"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </div>
      </v-tab-item>
      <v-tab-item style="background-color: rgba(57, 74, 88, 0.2)">
        <v-col>
          <v-btn @click="dialog = true" color="red" class="white--text"> <v-icon left>mdi-plus</v-icon>Добавить </v-btn>
        </v-col>
        <v-data-table :headers="headers" :items="categoriesListInCurrentProduct" disable-pagination hide-default-footer>
          <template v-slot:item.actions="{ item }">
            <v-btn @click="deleteCategory(item)" color="red" text>
              <v-icon left>mdi-delete-outline</v-icon>
              Удалить
            </v-btn>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog v-model="dialog">
      <v-card>
        <v-card-title>Выберите категорию</v-card-title>
        <v-data-table :headers="headers" :items="categories" :loading="isMenuListLoading" disable-pagination hide-default-footer>
          <template v-slot:item.actions="{ item }">
            <v-btn text color="red" @click="addItemCategoryClick(item.token)">
              <v-icon left>mdi-plus</v-icon>
              Добавить</v-btn
            >
          </template>
        </v-data-table>
        <v-card-actions>
          <v-btn color="red" class="white--text my-1 ml-auto" @click="dialog = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="notification">
      <Notification />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import UploadPictureDialog from '@/components/food/UploadPictureDialog';
import NavBar from '@/components/NavBar';
import Notification from '@/components/Notification';
import config from '@/config/config';

export default {
  data() {
    return {
      selectedTabs: null,
      isNewProduct: true,
      dialog: false,
      actionMessage: '',
      actionDialog: false,
      editedItem: {
        amountLeft: '',
        calories: '',
        carbohydrates: '',
        descriptionRu: '',
        fats: '',
        nameRu: '',
        price: '',
        proteins: '',
        weight: '',
        image: null,
        imageId: null,
        tax: {
          token: ''
        }
      },
      breadCrumbsItems: [
        {
          text: 'Выбор ресторана',
          exact: true,
          disabled: false,
          to: '/control'
        },
        {
          text: 'Выбор продуктов',
          exact: true,
          disabled: false,
          to: `/control/cafes/${this.$route.params.cafeToken}`
        },
        {
          text: 'Работа с продуктом',
          exact: true,
          disabled: true,
          to: `/control/cafes/${this.$route.params.cafeToken}/products/${this.$route.params.productToken}`
        }
      ],
      imageId: null,
      notEmptyRulesForNumber: [(v) => v >= 0 || 'Обязательное поле (введите число)'],
      notEmptyRules: [(v) => !!v || 0 || 'Обязательное поле'],
      validationRules: {
        weightRules: [(v) => (v <= 9999 && v >= 0) || 'Некорректное число (макс. 9999)'],
        amountRules: [
          (v) => {
            const pattern = /^\d{0,4}?$/;
            return (pattern.test(v) && v <= 9999 && v >= 0) || 'Некорректное число (макс. 9999)';
          }
        ],
        nameForm: [(v) => v.length < 64 || 'Превышено количество символов (макс. 64 символа)'],
        descriptionForm: [(v) => v.length < 512 || 'Превышено количество символов (макс. 512 символов)'],
        priceForm: [
          (value) => {
            const pattern = /^\d{0,4}(\.\d{2,2})?$/;
            return pattern.test(value) || 'Некорректное число (макс. цена - 9999.99)';
          }
        ],
        caloriesForm: [
          (value) => {
            const pattern = /^\d{0,4}(\.\d{1,1})?$/;
            return pattern.test(value) || 'Некорректное число (макс. количество калорий - 9999.9)';
          }
        ],
        energyValueForm: [
          (value) => {
            const pattern = /^\d{0,2}(\.\d{0,1})?$/;
            return pattern.test(value) || 'Некорректное число (макс. количество - 99.9)';
          }
        ]
      }
    };
  },
  components: {
    UploadPictureDialog,
    NavBar,
    Notification
  },
  computed: {
    headers() {
      return [
        { text: 'Название', value: 'nameRu' },
        { text: '', value: 'actions', sortable: false }
      ];
    },
    currentMenu() {
      let currMenu = null;
      if (this.menuList !== undefined && this.menuList !== null && this.menuList.length > 0 && this.isNewProduct === false) {
        currMenu = this.menuList.find((menu) => menu.token === this.$route.params.productToken);
        currMenu = JSON.parse(JSON.stringify(currMenu));
      }
      return currMenu;
    },
    categoriesListInCurrentProduct() {
      let categories = [];
      if (this.categoriesList !== undefined && this.categoriesList.length > 0 && this.isNewProduct === false) {
        this.currentMenu.categories.map((currCategories) => {
          categories.push(this.categoriesList.find((category) => category.token === currCategories.token));
        });
      }
      return categories;
    },
    categories() {
      let categoriesArray = [];
      if (this.categoriesList !== undefined && this.categoriesList.length > 0 && this.isNewProduct === false) {
        this.categoriesList.forEach((category) => {
          if (this.currentMenu.categories.find((cat) => cat.token === category.token) == undefined) categoriesArray.push(category);
        });
      }
      return categoriesArray;
    },
    taxList() {
      let taxArr = [];
      if (this.taxGroups !== undefined && this.taxGroups !== null && this.taxGroups.length > 0) {
        this.taxGroups.forEach((tax) => taxArr.push({ text: tax.desc, value: tax.token }));
      }
      return taxArr;
    },
    ...mapGetters('admin', ['menuList']),
    ...mapGetters('admin', ['categoriesList']),
    ...mapGetters('admin', ['isMenuListLoading']),
    ...mapGetters('admin', ['menuListResult']),
    ...mapGetters('admin', ['isMenuListError']),
    ...mapGetters('admin', ['createItemResult']),
    ...mapGetters('admin', ['createItem']),
    ...mapGetters('admin', ['editItemResult']),
    ...mapGetters('category', ['addItemCategoryResult']),
    ...mapGetters('category', ['deleteItemCategoryResult']),
    ...mapGetters('admin', ['editItemErrorMessage']),
    ...mapGetters('admin', ['createItemErrorMessage']),
    ...mapGetters('category', ['addItemCategoryErrorMessage']),
    ...mapGetters('category', ['deleteItemCategoryErrorMessage']),
    ...mapGetters('category', ['deleteItemCategoryResult']),
    ...mapGetters('menu', ['taxGroups']),
    ...mapGetters('menu', ['menuListErrorMessage']),
    ...mapGetters('picture', ['picture'])
  },
  methods: {
    url() {
      return window.location.origin + '/';
    },
    checkInput() {
      return true;
    },
    getCurrMenu() {
      if (this.$route.params.productToken !== 'new') {
        this.isNewProduct = false;
        this.fetchMenuList(this.$route.params.cafeToken);
      } else {
        this.isNewProduct = true;
      }
    },
    clickButton() {
      if (this.$refs.form.validate()) {
        if (this.$route.params.productToken !== 'new') {
          this.editedItem.cafeToken = this.$route.params.cafeToken;
          this.editedItem.itemToken = this.$route.params.productToken;
          if (this.picture !== undefined && this.picture !== null) {
            this.editedItem.img = this.picture.path;
            this.editedItem.imageId = this.picture.id;
          }
          this.editCurrItem(this.editedItem)
            .then(() => {
              if (this.editItemResult === true) {
                this.showNotification('Товар успешно отредактирован', 'success', '2000', 'mdi-check');
              }
              if (this.editItemResult === false) {
                this.showNotification(this.editItemErrorMessage, 'success', '2000', 'mdi-check');
              }
            })
            .catch(() => {
              this.showNotification(`Возникла ошибка: ${this.editItemErrorMessage}`, 'red', '2000', 'mdi-check');
            });
        } else {
          this.editedItem.cafeToken = this.$route.params.cafeToken;
          this.editedItem.taxToken = this.editedItem.tax.token;
          if (this.picture !== undefined && this.picture !== null) {
            this.editedItem.img = this.picture.path;
            this.editedItem.imageId = this.picture.id;
          }
          this.addNewItem(this.editedItem)
            .then(() => {
              if (this.createItemResult === true) {
                this.fetchMenuList(this.$route.params.cafeToken);
                this.showNotification('Товар создан успешно', 'success', '2000', 'mdi-check');
                this.$router.push(`/control/cafes/${this.$route.params.cafeToken}/products/${this.createItem.token}`);
              }
            })
            .catch(() => {
              this.showNotification(`Возникла ошибка: ${this.createItemErrorMessage}`, 'red', '2000', 'mdi-alert-circle-outline');
            });
        }
      }
    },
    deleteCategory(item) {
      this.deleteItemCategory({
        itemToken: this.$route.params.productToken,
        categoryToken: item.token
      })
        .then(() => {
          this.fetchMenuList(this.$route.params.cafeToken);
          if (this.deleteItemCategoryResult === true) {
            this.showNotification('Категория успешно удалена', 'success', '2000', 'mdi-check');
          }
        })
        .catch(() => {
          this.showNotification(`Возникла ошибка: ${this.deleteItemCategoryErrorMessage}`, 'red', '2000', 'mdi-alert-circle-outline');
        });
    },
    addItemCategoryClick(categoryToken) {
      this.addItemCategory({
        itemToken: this.$route.params.productToken,
        categoryToken: categoryToken
      })
        .then(() => {
          this.dialog = false;
          this.fetchMenuList(this.$route.params.cafeToken);
          if (this.addItemCategoryResult === true) {
            this.showNotification('Категория успешно добавлена', 'success', '2000', 'mdi-check');
          }
        })
        .catch(() => {
          this.showNotification(`Возникла ошибка: ${this.addItemCategoryErrorMessage}`, 'red', '2000', 'mdi-alert-circle-outline');
        });
    },
    fetchTaxGroups() {
      this.getTaxGroups();
    },
    showNotification(message, color, timer, icon) {
      this.showSnack({
        message,
        color,
        timer,
        icon
      });
    },
    ...mapActions('admin', ['fetchMenuList']),
    ...mapActions('admin', ['addNewItem']),
    ...mapActions('admin', ['editCurrItem']),
    ...mapActions('category', ['addItemCategory']),
    ...mapActions('category', ['deleteItemCategory']),
    ...mapActions('picture', ['destroyProductPicture']),
    ...mapActions('menu', ['getTaxGroups']),
    ...mapActions('notification', ['showSnack'])
  },
  mounted() {
    this.getCurrMenu();
    this.fetchTaxGroups();
  },
  beforeDestroy() {
    this.destroyProductPicture();
  },
  watch: {
    currentMenu(newVal) {
      if (newVal !== null) {
        this.editedItem = newVal;
      }
    }
  }
};
</script>
<style scoped>
.v-breadcrumbs >>> a {
  color: red;
}
.notification {
  position: fixed;
  bottom: 5px;
  right: 5px;
}
.v-image,
video {
  border-radius: 10px;
}
</style>
